import { useEffect, useState } from 'react'

import { useLead, useMapper } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import { formatters, goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

export const useEditInstallmentValue = ({ proposal = {}, setProduct }) => {
  const [loading, setLoading] = useState(false)
  const { changeLeadData } = useLead()
  const { mapSimulationsWhenEdit } = useMapper()

  const { number } = formatters

  useEffect(() => {
    changeLeadData({
      previousStep: 'simulation'
    })
  }, [])

  const onSubmit = async (payload) => {
    try {
      setLoading(true)

      const { installmentsValue } = payload

      const requestBody = {
        value: proposal?.loanTotal,
        installments_value: number.currencyToFloat(installmentsValue)
      }
      const newData = await http.proposal.editProposalData(
        proposal?.offerUuid,
        requestBody
      )

      const formattedData = mapSimulationsWhenEdit(newData)

      setProduct({ proposal: formattedData })

      goTo('simulation')
    } catch (err) {
      setLoading(false)

      const errorPayload = {
        error: err?.message,
        requestURL: err?.request?.responseURL
      }

      DDPageError({
        name: 'useEditInstallmentValue | editProposal',
        payload: errorPayload
      })
    }
  }

  return { onSubmit, loading }
}
