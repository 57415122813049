import { useForm } from 'react-hook-form'

import { Navigation, Input } from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import './edit-installment-value.scss'
import { useEditInstallmentValue } from './useEditInstallmentValue'

const EditInstallmentValue = ({ pageContext }) => {
  const { product, setProduct } = useProposal()
  const proposal = product?.proposal
  const initialValues = {
    installmentsValue: proposal?.installmentsValue
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { onSubmit, loading } = useEditInstallmentValue({
    proposal,
    setProduct
  })

  return (
    <ProposalLayout pageContext={pageContext}>
      <div className="edit_installment_value">
        <h1 className="edit_installment_value__title">
          Como você quer parcelar
        </h1>
        <h2 className="edit_installment_value__subtitle">
          Escolha quanto você precisa e como quer pagar
        </h2>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Input.Currency
            id="installmentsValue"
            label="Valor da parcela"
            type="currency"
            name="installmentsValue"
            disabled={loading}
            defaultValue={proposal?.installmentsValue}
            rules={{
              min: proposal?.minLoanInstallmentsValue,
              max: proposal?.maxLoanInstallmentsValue
            }}
            control={control}
          />
          <Navigation
            submit
            nextStep
            isNextStepButtonLoading={loading}
            isNextButtonDisabled={!isValid}
            nextButtonId="edit-installment-value"
          />
        </form>
      </div>
    </ProposalLayout>
  )
}

export default EditInstallmentValue
